/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React, { useContext } from 'react'

import Dgp from '../../../../../assets/images/partners/dgp.svg'
import Dptv from '../../../../../assets/images/partners/dp-tv.svg'
import OsvitnyaT from '../../../../../assets/images/partners/osvitnyaT.svg'
import Minosv from '../../../../../assets/images/partners/min-osv.svg'
import Deptran from '../../../../../assets/images/partners/dep-tran.svg'
import Instosv from '../../../../../assets/images/partners/inst-osv.svg'
import Dprada from '../../../../../assets/images/partners/dp-rada.svg'
import Pdftron from '../../../../../assets/images/partners/pdftron.png'
import arrowClick from '../../../../../assets/images/partners/arrowClick.svg'
import Esl from '../../../../../assets/images/partners/esl.png'
import Logos from '../../../../../assets/images/partners/logos.png'

import '../styles.module.scss'
import { ModalContext } from '../../../../Base/ModalContainer'

const PartnersContainer = () => {
  const { openModal } = useContext(ModalContext)

  return <Partners onButtonClick={openModal} />
}

const Partners = ({ onButtonClick }) => (
  <div id="ex3" className="partners d-flex">
    <div data-aos="flip-left" className="partners__left flip-cards">
      <div className="our__partners long__partners">
        <a href="https://humpolitic.dniprorada.gov.ua/" target="_blank" rel="noreferrer">
          <img src={Dgp} alt="" className="partners__logo partners__grayscale" />
        </a>
      </div>

      <div className="partners__monroznov">
        <div className="partners__monroz">
          <div className="our__partners partners_gradient">
            <h3>
              Партнери <br /> єДніпро
            </h3>
          </div>

          <div className="our__partners">
            <a href="https://otd.dniprorada.gov.ua/" target="_blank" rel="noreferrer">
              <img src={OsvitnyaT} alt="" className="partners__logo partners__grayscale" />
            </a>
          </div>
        </div>

        <div className="our__partners partners__high partners__high_mob">
          <a href="https://iea.gov.ua/" target="_blank" rel="noreferrer">
            <img src={Instosv} alt="" className="partners__logo partners__grayscale" />
          </a>
        </div>
      </div>
    </div>

    <div data-aos="flip-up" className="partners_middle flip-cards">
      <div className="partners__topline">
        <div className="our__partners">
          <a href="https://dnipro.tv/" target="_blank" rel="noreferrer">
            <img src={Dptv} alt="" className="partners__logo partners__grayscale" />
          </a>
        </div>

        <div className="our__partners">
          <a href="https://www.pdftron.com/" target="_blank" rel="noreferrer">
            <img src={Pdftron} alt="pdftron" className="partners__logo partners__grayscale" />
          </a>
        </div>
      </div>

      <div className="our__partners long__partners">
        <a
          href="https://dniprorada.gov.ua/uk/page/departament-transportu-ta-transportnoi-infrastrukturi-dniprovskoi-miskoi-radi"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Deptran} alt="" className="partners__logo partners__grayscale" />
        </a>
      </div>

      <div className="our__partners long__partners">
        <a href="https://mon.gov.ua/ua" target="_blank" rel="noreferrer">
          <img src={Minosv} alt="" className="partners__logo partners__grayscale" />
        </a>
      </div>
    </div>

    <div data-aos="flip-right" className="partners__right flip-cards">
      <div className="our__partners partners__for_long">
        <a href="https://dniprorada.gov.ua/uk" target="_blank" rel="noreferrer">
          <img src={Dprada} alt="" className="partners__logo partners__grayscale" />
        </a>
      </div>

      <div className="our__partners partners__high partners__for_long partners__pointer">
        <button className="openModalBtn" type="button" onClick={onButtonClick}>
          <h3 id="offer">Наступним партнером можеш стати саме ти</h3>
        </button>

        <img src={arrowClick} className="arrowImg" alt="Натисни, щоб стати нашим партнером" />
      </div>
    </div>

    <div data-aos="flip-bottom" className="partners__bottom flip-cards col-lg-12 d-flex">
      <div className="our__partners part_longest d-flex">
        <a href="https://eslukraine.com/" className='col-lg-4' target="_blank" rel="noreferrer">
          <img src={Esl} alt="" className="partners__logo partners__grayscale esl" />
        </a>
      </div>

      <div className="our__partners part_longest col-12">
        <a href="https://apslogos.com/" className='col-lg-8' target="_blank" rel="noreferrer">
          <img src={Logos} alt="" className="partners__logo partners__grayscale logos" />
        </a>
      </div>
    </div>
  </div>
)

export default React.memo(PartnersContainer)

import React from "react";
import { Link } from "react-router-dom";

import Aaaa from "../../../../../assets/images/wallpapers/aaaa.png";
import Dddd from "../../../../../assets/images/wallpapers/dddd.png";
import Eeee from "../../../../../assets/images/wallpapers/eeee.png";

const Wallpapers = () => {
  return (
    <div className="projects_content wallpapers">
      <div className="projects_text">
        <div className="project__text">
          <h2>Створення бренду міста в інформаційному просторі</h2>
          <p>
            Підбірка авторських патріотичних шпалерів із найгарніших місць від
            єДніпро!
          </p>
        </div>
        <div className="divButton">
          <p>Завантажити файли зі шпалерами можна</p>
          <button className="knopka__opacity button__download" type="button">
            <Link to="/wallpaper-page" className="linkwallpaperpage">
              тут
            </Link>
          </button>
        </div>
      </div>
      <div className="wallpapers__img_block">
        <div className="wallpapers__img_content">
          <img className="wallpapers__img w_limg" src={Aaaa} alt="" />
          <img className="wallpapers__img w_cimg" src={Dddd} alt="" />
          <img className="wallpapers__img w_rimg" src={Eeee} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Wallpapers;

import React, { useEffect } from 'react'
import AOS from 'aos'
import Footer from './components/Base/Footer'
import Header from './components/Base/Header'
import ModalContainer from './components/Base/ModalContainer'
import Router from './components/Pages/Home/Router'

import './App.scss'
import 'aos/dist/aos.css'

const App = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <ModalContainer>
        <Header />
        <Router />
        <Footer />
      </ModalContainer>
    </>
  )
}

export default App

import React from 'react'
import { useWindowSize } from '@uidotdev/usehooks'

import Logo from '../../../assets/images/footer/logo_footer.svg'
import iconInstagram from '../../../assets/images/footer/icon-instagram.png'
import iconFacebook from '../../../assets/images/footer/icon-facebook.png'
import iconTelegram from '../../../assets/images/footer/icon-telegram.png'

import styles from './styles.module.scss'

const Footer = () => {
  const Phones = ['+38 (050) 876-94-49', '+38 (063) 876-94-49', '+38 (067) 876-94-49']
  const { width } = useWindowSize();

  const replacePhoneNumber = (NumberPhone) => NumberPhone.replace(/\D/g, '')

  const footerContent = [
    {
      socialLink: "https://instagram.com/ednipro",
      imgSrc: iconInstagram,
      imgAlt: 'Instagram',
    },
    {
      socialLink: "https://www.facebook.com/kpednipro",
      imgSrc: iconFacebook,
      imgAlt: 'Facebook',
    },
    {
      socialLink: "https://t.me/kp_ednipro",
      imgSrc: iconTelegram,
      imgAlt: 'Telegram',
    },
  ]

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__eDnipro}>
        <img src={Logo} alt="logo eDnipro" className={styles.footer__logo} />
        {width > 1024 && <p>КП "єДніпро" © 2022 — {new Date().getFullYear()}</p>}
      </div>

      <div className={styles.footer__contact}>
        <div className={styles.footer_mob}>
          <p>Ми завжди на зв'язку:</p>
          <div className={styles.footer__contacts}>
            {Phones.map((e) => (
              <a href={`tel:${replacePhoneNumber(e)}`} className="asd">
                <p>{e}</p>
              </a>
            ))}
          </div>
        </div>

        <div className={styles.footer_social}>
          {footerContent.map((item) => (
            <a
              href={item.socialLink}
              className="footer__link_social"
              target="_blank"
              rel="noreferrer"
            >
              <img src={item.imgSrc} alt={item.imgAlt} className="social__icon" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer

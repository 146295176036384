import React from 'react'
import './styles.module.scss'

const Preview = () => {
  const registerText = ['Просто. Цілодобово. В одному місці.', 'Усе місто у твоїй кишені.'];

  const scrollToSection = (event, link) => {
    event.preventDefault();
    window.history.pushState("", document.title, window.location.pathname);

    const section = document.getElementById(link);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="register">
      <div className="register__content">
        <div className="register__text">
          <h2>Дніпро цифровізується |</h2>
          {registerText.map((e) => (
            <p className="register__text_p">{e}</p>
          ))}
        </div>
        <div className="divButton">
          <a
            className="button__link button"
            onClick={(event) => scrollToSection(event, "ex1")}
          >
            Діджиталізуйся!
          </a>
        </div>
      </div>
    </div>
  )
}

export default Preview

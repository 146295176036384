import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import Artem from "../../../../../assets/images/team/Artem.png";
import Olena from "../../../../../assets/images/team/Olena.png";
import Roma from "../../../../../assets/images/team/Roma.png";
import Lys from "../../../../../assets/images/team/lys.png";
import Rita from "../../../../../assets/images/team/Rita.png";
import Dima from "../../../../../assets/images/team/Dima.png";
import SonyaPR from "../../../../../assets/images/team/Sonya.png";
import Ihor from "../../../../../assets/images/team/Ihor.png";
import Ganna from "../../../../../assets/images/team/Ganna.png";
import Darya from "../../../../../assets/images/team/Darya.png";

import "swiper/css";
import "./styles.module.scss";

const Team = () => {
	SwiperCore.use([Autoplay]);

	const teamPearson = [
		{
			staffName: "Артем Трапезніков",
			jobsPosition: "Директор КП",
			staffImg: Artem,
			imgAlt: "Артем Трапезніков",
		},
		{
			staffName: "Роман Ростовський",
			jobsPosition: "Заступник директора",
			staffImg: Roma,
			imgAlt: "Роман Ростовський",
		},
		{
			staffName: "Маргарита Сорсорян",
			jobsPosition: "Консультантка",
			staffImg: Rita,
			imgAlt: "Маргарита Сорсорян",
		},
		{
			staffName: "Дмитро Коптєв",
			jobsPosition: "Консультант",
			staffImg: Dima,
			imgAlt: "Дмитро Коптєв",
		},
		{
			staffName: "Софія Приліпко",
			jobsPosition: "PR-менеджерка",
			staffImg: SonyaPR,
			imgAlt: "Софія Приліпко",
		},
		{
			staffName: "Ігор Головешкин",
			jobsPosition: "Дизайнер",
			staffImg: Ihor,
			imgAlt: "Ігор Головешкин",
		},
		{
			staffName: "Ганна Павлюченко",
			jobsPosition: "Начальник відділу підтримки проєктів цифрового розвитку",
			staffImg: Ganna,
			imgAlt: "Ганна Павлюченко",
		},
		{
			staffName: "Дар’я Моргун",
			jobsPosition: "Заступник начальника відділу підтримки проєктів цифрового розвитку",
			staffImg: Darya,
			imgAlt: "Дар’я Моргун",
		},
		{
			staffName: "Катерина Лисенко",
			jobsPosition: "Оператор відділу підтримки проєктів цифрового розвитку",
			staffImg: Lys,
			imgAlt: "Катерина Лисенко",
		},
	];

	return (
		<div id="ex5" className="projects_content our_team">
			<h3>Наша команда</h3>
			<Swiper
				autoplay={{ delay: 1500 }}
				className="staff__team"
				spaceBetween={10}
				slidesPerView={2}
				onSlideChange={() => console.log("slide change")}
				onSwiper={(swiper) => console.log(swiper)}
				speed={1500}
				loop
			>
				{teamPearson.map((e) => (
					<SwiperSlide className="staff">
						<div className="staff__text">
							<h4>{e.staffName}</h4>
							<p>{e.jobsPosition}</p>
						</div>
						<img src={e.staffImg} alt={e.imgAlt} className="staff__photo" />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Team;

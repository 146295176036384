/* eslint-disable react/prop-types */

import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import "./styles.module.scss";

const ModalContainer = ({ closeModal }) => {
  const [state, setState] = useState({
    company: "",
    phone: "",
    representative: "",
    email: "",
  });

  const setValue = useCallback(
    (key) => (e) => {
      setState({
        ...state,
        [key]: e.currentTarget.value,
      });
    },
    [state]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      const data = axios.post("https://api.eosvita.dniprorada.gov.ua/mail", {
        company: state.company,
        representative: state.representative,
        phone: state.phone,
        mail: state.email,
      });
    } catch (err) {
      console.log(err);
    }
    console.log(`Form to send: ${JSON.stringify(state)}`);
  };

  return createPortal(
    <div className="modalBackground">
      <form className="modalContainer" onSubmit={onSubmit}>
        <div className="titleCloseBtn">
          <button type="button" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="title">
          <h3>Вітаємо!</h3>
          <p>
            Ми майже друзі, залишився маленький крок - заповнити партнерську
            форму
          </p>
        </div>
        <div className="body">
          <div className="body__left">
            <input
              type="text"
              placeholder="Назва бренду"
              id=""
              value={state.company}
              onChange={setValue("company")}
            />
            <input
              type="tel"
              placeholder="Номер телефону"
              id=""
              value={state.phone}
              onChange={setValue("phone")}
            />
          </div>
          <div className="body__right">
            <input
              type="text"
              placeholder="Контактна особа"
              id=""
              value={state.representative}
              onChange={setValue("representative")}
            />
            <input
              type="email"
              placeholder="E-mail"
              id=""
              value={state.email}
              onChange={setValue("email")}
            />
          </div>
        </div>
        <div className="footer">
          <button type="submit" id="Send">
            Надіслати
          </button>
        </div>
      </form>
    </div>,
    document.getElementById("portal")
  );
};

export default React.memo(ModalContainer);

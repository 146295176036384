import React from "react";

import osvitaImg from "../../../../../assets/images/osvita/osvita.png";

import "./styles.module.scss";

const EduPlaces = () => {
  return (
    <div className="projects_content eduplaces">
      <div className="projects_text">
        <div className="ebook__text">
          <h2>Обирай своє майбутнє</h2>
          <p>Усі заклади освіти Дніпра в одному місці</p>
        </div>
        <div className="divButton">
          <button className="knopka__opacity" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://osvita.dniprorada.gov.ua/"
              className="button__link"
            >
              osvita.dniprorada.gov.ua
            </a>
          </button>
        </div>
      </div>
      <div className="ebook__img_block box">
        <img className="img" src={osvitaImg} alt="" />
      </div>
    </div>
  );
};

export default EduPlaces;

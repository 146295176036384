import React from "react";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";

import Content from "./Content";
import Wallpaperpage from "../WallpaperPage";
import NotFoundPage from "../NotFoundPage";

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/wallpaper-page" element={<Wallpaperpage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;

/* eslint-disable react/prop-types */
import React, { createContext, useState, useCallback } from 'react'
import Modal from '../../Pages/Home/Content/Modal'
import ModalPartner from '../../Pages/Home/Content/ModalPartner'

export const ModalContext = createContext()

const ModalContainer = ({ children }) => {
  const [modalState, setModalState] = useState(false)

  const closeModal = useCallback(() => setModalState(false), [])
  const openModal = useCallback(() => setModalState(true), [])

  const [modalState1, setModalState1] = useState(false)

  const closeModal1 = useCallback(() => setModalState1(false), [])
  const openModal1 = useCallback(() => setModalState1(true), [])

  return (
    <ModalContext.Provider value={{ openModal, closeModal, openModal1, closeModal1 }}>
      {children}
      {modalState && <Modal closeModal={closeModal} />}
      {modalState1 && <ModalPartner closeModal={closeModal1} />}
    </ModalContext.Provider>
  )
}

export default React.memo(ModalContainer)

import React, { useRef, useEffect } from "react";

const Ua = () => {
  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);

  return (
    <div>
      <div className="projects_content ebook">
        <div className="projects_text">
          <div className="ebook__text">
            <h2>Українською, будь ласка!</h2>
            <p>
              Перевірте свої знання з української мови за допомогою
              онлайн-тесту, який покаже рівень Вашої обізнаності у рідній мові.
            </p>
          </div>
          <div className="divButton">
            <button className="knopka__opacity" type="button">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ua.dniprorada.gov.ua/"
                className="button__link"
              >
                ua.dniprorada.gov.ua
              </a>
            </button>
          </div>
        </div>
        <div className="ebook__img_block box">
          <video autoplay loop muted playsInline ref={vidRef} width="80%">
            {/* <source
              src="https://ua.dniprorada.gov.ua/api/uploads/ua.webm"
              type="video/webm"
            /> */}
            <source
              src="https://ua.dniprorada.gov.ua/api/uploads/ua.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Ua;

import React, { useContext } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

import Gonchara from '../../../../../assets/images/memorandums/gonchara.png';
import AcadBud from '../../../../../assets/images/memorandums/acadBud.png';
import Mvd from '../../../../../assets/images/memorandums/mvd.png';
import Politechdp from '../../../../../assets/images/memorandums/politechdp.png';
import Itstep from '../../../../../assets/images/memorandums/itstep.png';
import Arrow from '../../../../../assets/images/memorandums/arrow.svg';

import './styles.modules.scss'

import { ModalContext } from '../../../../Base/ModalContainer'

const PartnersContainer = () => {
    const { openModal1 } = useContext(ModalContext)

    return <Memorandum onButtonClick={openModal1} />
}

const Memorandum = ({ onButtonClick }) => {

    const { width } = useWindowSize();
    const MemorandumItem = [
        { currentImgMem: Gonchara, currentTextMem: 'Дніпровським національним університетом імені Олеся Гончара' },
        { currentImgMem: AcadBud, currentTextMem: 'Придніпровська державна академія будівництва та архітектури' },
        { currentImgMem: Mvd, currentTextMem: 'Дніпропетровським державним університетом внутрішніх справ' },
        { currentImgMem: Politechdp, currentTextMem: 'Національним технічним університетом' },
        { currentImgMem: Itstep, currentTextMem: 'Дніпропетровським технологічним університетом ШАГ' }
    ]

    return (
        <div className='projects_content memorandum' id='ex2'>
            <div className="memorandum__prew col-lg-10">
                <h2>Меморандуми про співпрацю вже підписано з:</h2>
            </div>
            <div className="memorandums__uni d-flex">
                {/* <div className="memorandums__part">
                    <div className="memorandums__part_img">
                        <img src="" alt="" />
                    </div>
                    <div className="memorandums__text">
                        <p>
                            
                        </p>
                    </div>
                </div> */}
                {
                    MemorandumItem.map((e) => (
                        <div className="memorandums__part d-flex col-xl-3 col-lg-6 col-md-6 col-12">
                            <div className="memorandums__part_img col-lg-5 col-md-5 col-12">
                                <img className='memorandums__img' src={e.currentImgMem} alt="" />
                            </div>
                            {width > 1200 ? (
                                <div className="memorandums__text col-lg-5 xol-md-6 col-12">
                                    <p>
                                        {e.currentTextMem}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    ))}
                <div className="memorandum__button_block d-flex col-lg-6 col-12">
                    <button className='memorandum__button' onClick={onButtonClick}>
                        Співпрацювати
                        <img className='memorandum__arrow' src={Arrow} alt="arrow" />
                    </button>
                </div>
            </div>

        </div>
    );
};

export default React.memo(PartnersContainer)
import React from "react";
import styles from "./styles.module.scss";
import Preview from "./Preview";
import OurProject from "./OurProject";
import Targets from "./Targets";
import Partners from "./Partners";
import Team from "./Team";
import Memorandum from "./Memorandum";

const Content = () => (
    <div className={styles.content}>
        <Preview />
        <OurProject />
        <Memorandum />
        <Partners />
        <Targets />
        <Team />
    </div>
);

export default Content;

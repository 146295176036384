/* eslint-disable react/no-array-index-key */
import React from "react";

import EduPlaces from "../EduPlaces/EduPlaces";
import Memory from "../Memory/Memory";
import Wallpapers from "../Wallpapers";
import Ua from "../Ua";

import BlueCard from "../../../../../assets/images/studContent/stud-card-bl.png";
import GreenCard from "../../../../../assets/images/studContent/stud-card-gr.png";
import OrangeCard from "../../../../../assets/images/studContent/stud-card-or.png";
import TeachCard from "../../../../../assets/images/studContent/teach-card.png";
import UkrLangImg from "../../../../../assets/images/nmt/ukr_lang.svg";
import UkrHistImg from "../../../../../assets/images/nmt/history.svg";
import MathImg from "../../../../../assets/images/nmt/math.svg";
import NmtImg from "../../../../../assets/images/nmt/nmt.png";
import Book from "../../../../../assets/images/ebook/book.png";
import VpoUkr from "../../../../../assets/images/vpo/vpo_ukr.png";
import DpVpo from "../../../../../assets/images/vpo/dp_vpo.svg";

import "./styles.module.scss";

const OurProject = () => {
  const projectsTextCard = [
    "-Право входу до навчального закладу",
    "-Безкоштовний проїзд у електротранспорті та знижка -25% на автобусні маршрути",
    "-Вплив на систему навчання через систему е-демократії",
    "-Доступ до дисконту у сфері роздрібної торгівлі та обслуговування населення, закладах культури тощо",
  ];

  const nmtText = [
    "Платформа для підготовки до Національного мультипредметного тестування",
    "Залучено 40% 11-класників з усієї України!",
  ];

  const ebookText = [
    "Твій онлайн-портфель з підручниками!",
    "Усі книжки в одному місці!",
  ];

  return (
    <div id="ex1" className="our_projects">
      <h3 className="title__block">Наші проєкти</h3>
      <Memory />
      <Wallpapers />
      <Ua />
      <EduPlaces />
      <div className="stud__content projects_content">
        <div id="stud_text" className="projects_text">
          <h2>Учнівський квиток</h2>
          <p>
            {projectsTextCard.map((e, i) => (
              <ul key={i} className="projects__text_card">
                <li>{e}</li>
              </ul>
            ))}
          </p>
          <div className="takeStud_content">
            <p>
              Аби отримати учнівський, необхідно заповнити електронну анкету за
              посиланням :{" "}
            </p>
            <div className="divButton">
              <button className="knopka__opacity" type="button">
                <a
                  target="_blank"
                  href="https://cabinet.dniprorada.gov.ua/"
                  className="button__link"
                  rel="noreferrer"
                >
                  cabinet.dniprorada.gov.ua
                </a>
              </button>
            </div>
          </div>
        </div>

        <div className="projects_cards projects__cards_stud">
          <div className="stud__cards">
            <div
              data-aos="zoom-in-down"
              id="card_gr"
              className="cards stud__cards_pos"
            >
              <img className="cartiz" src={GreenCard} alt="Student card" />
            </div>

            <div
              data-aos="zoom-in-left"
              id="card_or"
              className="cards stud__cards_pos"
            >
              <img className="cartiz" src={OrangeCard} alt="Student card" />
            </div>

            <div
              data-aos="zoom-in-right"
              id="card_blue"
              className="cards stud__cards_pos"
            >
              <img className="cartiz" src={BlueCard} alt="Student card" />
            </div>

            <div id="card_teach" className="cards" data-aos="zoom-in-left">
              <img className="cartiz" src={TeachCard} alt="Student card" />
            </div>
          </div>
        </div>
      </div>

      <div className="projects_content projects_content_nmt">
        <div className="projects_text">
          <div className="nmt__text">
            <h2>Платформа НМТ</h2>
            {nmtText.map((e) => (
              <p>{e}</p>
            ))}
          </div>
          <div className="divButton">
            <button className="knopka__opacity" type="button">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://edu.dniprorada.gov.ua/"
                className="button__link"
              >
                edu.dniprorada.gov.ua
              </a>
            </button>
          </div>
        </div>

        <div id="nmt" className="projects_cards">
          <div className="projects_icons">
            <img
              id="ukr_lang"
              className="nmt__icons"
              src={UkrLangImg}
              alt="ukrainian language"
              data-aos="fade-up"
              data-aos-duration="1500"
            />
            <img
              id="ukr_history"
              className="nmt__icons"
              src={UkrHistImg}
              alt="ukrainian history"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
            <img
              id="mathematics"
              className="nmt__icons"
              src={MathImg}
              alt="math"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <img
            id="nmt__screen"
            src={NmtImg}
            alt="National Multidisciplinary Testing"
          />
        </div>
      </div>

      <div className="projects_content ebook">
        <div className="projects_text">
          <div className="ebook__text">
            <h2>Mybook</h2>
            {ebookText.map((e) => (
              <p>{e}</p>
            ))}
          </div>
          <div className="divButton">
            <button className="knopka__opacity" type="button">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://mybook.dniprorada.gov.ua/"
                className="button__link"
              >
                mybook.dniprorada.gov.ua
              </a>
            </button>
          </div>
        </div>
        <div className="ebook__img_block box">
          <img className="iBook" src={Book} alt="" />
        </div>
      </div>

      <div className="projects_content vpo">
        <div className="projects_text">
          <div className="vpo__text">
            <h2>
              Платформа <br />
              для ВПО
            </h2>
            <p>
              Опитувальник для з’ясування кількості переселенців, які ще не
              подали документи у школи, та щоб допомогти їм це зробити.
            </p>
          </div>
          <div className="divButton">
            <button className="knopka__opacity" type="button">
              <a
                target="_blank"
                href="https://vpo.dniprorada.gov.ua/"
                className="button__link"
                rel="noreferrer"
              >
                vpo.dniprorada.gov.ua
              </a>
            </button>
          </div>
        </div>
        <div className="vpo__img_block">
          <img className="vpo__img" src={VpoUkr} alt="Платформа для ВПО" />
          <img
            className="dp_vpo_img"
            src={DpVpo}
            alt="Платформа для ВПО Дніпровська область"
          />
          <div className="blob blue" />
        </div>
      </div>
    </div>
  );
};

export default OurProject;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";

import wallpaper1 from "../../../../src/assets/images/wallpaperPage/wallpaper(1).png";
import wallpaper2 from "../../../../src/assets/images/wallpaperPage/wallpaper(2).png";
import wallpaper3 from "../../../../src/assets/images/wallpaperPage/wallpaper(3).png";
import wallpaper4 from "../../../../src/assets/images/wallpaperPage/wallpaper(4).png";
import wallpaper5 from "../../../../src/assets/images/wallpaperPage/wallpaper(5).png";
import wallpaper6 from "../../../../src/assets/images/wallpaperPage/wallpaper(6).png";
import wallpaper7 from "../../../../src/assets/images/wallpaperPage/wallpaper(7).png";
import wallpaper8 from "../../../../src/assets/images/wallpaperPage/wallpaper(8).png";
import wallpaper9 from "../../../../src/assets/images/wallpaperPage/wallpaper(9).png";
import wallpaper10 from "../../../../src/assets/images/wallpaperPage/wallpaper(10).png";
import wallpaper11 from "../../../../src/assets/images/wallpaperPage/wallpaper(11).png";
import wallpaper12 from "../../../../src/assets/images/wallpaperPage/wallpaper(12).png";
import wallpaper13 from "../../../../src/assets/images/wallpaperPage/wallpaper(13).png";
import wallpaper14 from "../../../../src/assets/images/wallpaperPage/wallpaper(14).png";
import wallpaper15 from "../../../../src/assets/images/wallpaperPage/wallpaper(15).png";
import wallpaper16 from "../../../../src/assets/images/wallpaperPage/wallpaper(16).png";
import wallpaper17 from "../../../../src/assets/images/wallpaperPage/wallpaper(17).png";

import "./styles.module.scss";

const WallpaperPage = () => {

  // Получаем текущий путь страницы с помощью хука useLocation()
  const location = useLocation();

  // При изменении пути страницы, прокручиваем страницу вверх
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Возвращаем содержимое вашей страницы

  const wallpapersImg = [
    {
      image: wallpaper1,
      alt: "Шпалера 1",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(1).png",
      link: "wallpapers/wallpaper(1).png"
    },
    {
      image: wallpaper2,
      alt: "Шпалера 2",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(2).png",
      link: "wallpapers/wallpaper(2).png"
    },
    {
      image: wallpaper3,
      alt: "Шпалера 3",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(3).png",
      link: "wallpapers/wallpaper(3).png"
    },
    {
      image: wallpaper4,
      alt: "Шпалера 4",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(4).png",
      link: "wallpapers/wallpaper(4).png"
    },
    {
      image: wallpaper5,
      alt: "Шпалера 5",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(5).png",
      link: "wallpapers/wallpaper(5).png"
    },
    {
      image: wallpaper6,
      alt: "Шпалера 6",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(6).png",
      link: "wallpapers/wallpaper(6).png"
    },
    {
      image: wallpaper7,
      alt: "Шпалера 7",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(7).png",
      link: "wallpapers/wallpaper(7).png"
    },
    {
      image: wallpaper8,
      alt: "Шпалера 8",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(8).png",
      link: "wallpapers/wallpaper(8).png"
    },
    {
      image: wallpaper9,
      alt: "Шпалера 9",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(9).png",
      link: "wallpapers/wallpaper(9).png"
    },
    {
      image: wallpaper10,
      alt: "Шпалера 10",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(10).png",
      link: "wallpapers/wallpaper(10).png"
    },
    {
      image: wallpaper11,
      alt: "Шпалера 11",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(11).png",
      link: "wallpapers/wallpaper(11).png"
    },
    {
      image: wallpaper12,
      alt: "Шпалера 12",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(12).png",
      link: "wallpapers/wallpaper(12).png"
    },
    {
      image: wallpaper13,
      alt: "Шпалера 13",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(13).png",
      link: "wallpapers/wallpaper(13).png"
    },
    {
      image: wallpaper14,
      alt: "Шпалера 14",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(14).png",
      link: "wallpapers/wallpaper(14).png"
    },
    {
      image: wallpaper15,
      alt: "Шпалера 15",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(15).png",
      link: "wallpapers/wallpaper(15).png"
    },
    {
      image: wallpaper16,
      alt: "Шпалера 16",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(16).png",
      link: "wallpapers/wallpaper(16).png"
    },
    {
      image: wallpaper17,
      alt: "Шпалера 17",
      downloader: "https://ednipro.dp.ua/wallpapers/wallpaper(17).png",
      link: "wallpapers/wallpaper(17).png"
    },
  ];

  //   const handleDownload = (downloader) => {
  //     const fileName = getFileNameFromURL(downloader);
  //     saveAs(downloader, fileName);
  //   };

  //   const getFileNameFromURL = (downloader) => {
  //     const pathSegments = downloader.split("https://ednipro.dp.ua/wallpapers/");
  //     return pathSegments[pathSegments.length - 1];
  //   };

  const handleDownload = async (downloader, alt) => {
    try {
      const response = await fetch(downloader);
      console.log(response)
      const blob = await response.blob();
      const fileName = `${alt}.png`;
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error while downloading the image:", error);
    }
  };

  const preloadImages = () => {
    wallpapersImg.forEach((item) => {
      const img = new Image();
      img.src = item.image;
    });
  };

  // Выполнить предварительную загрузку изображений
  preloadImages();

  return (
    <div className="wallpapers__page">
      <div className="wallpapers__prev_content col-xl-11">
        <div className="wallpapers__prev_text col-lg-4">
          <h2 className="wallpapers__text_title">
            Якщо у вас є цікаві фото міста можете поділитися ними і ми зробимо з
            них шпалери
          </h2>
        </div>
        <div className="divButton col-lg-4">
          <button className="knopka__opacity button__download" type="button">
            <a
              //   target="_blank"
              href="https://ednipro.dp.ua/Шпалери.zip"
              className="button__link"
              rel="noreferrer"
            >
              Завантажити усі шпалери
            </a>
          </button>
        </div>
      </div>

      <div className="project__wallpapers_content col-xl-11">
        <h3 className="mb-5">Шпалери з 3D-ефектом</h3>

        <div className="wallpaper__img_container d-flex">
          {wallpapersImg.map((e, index) => (
            <div key={index} className="wallpaper__content">
              <a
                target="_blank"
                href={e.link}
                rel="noreferrer"
                className="wallpaper__link"
                download={true}
              >
                <img className="wallpaper__img" src={e.image} alt={e.alt} />
              </a>
              <button
                className="knopka__opacity button__download wallButton"
                onClick={() => handleDownload(e.downloader, e.alt)}
              >
                Завантажити шпалери
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WallpaperPage;

import React from "react";

import memoryImg from "../../../../../assets/images/memory/memory.png";

import "./styles.module.scss";

const Memory = () => {
  return (
    <div className="projects_content eduplaces">
      <div className="projects_text">
        <div className="ebook__text">
          <h2>Сайт Війна</h2>
          <p>
            Уся хронологія повномасштабного вторгнення на території міста Дніпра
            на одній платформі
          </p>
        </div>
        <div className="divButton">
          <button className="knopka__opacity" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://memory.dp.ua/"
              className="button__link"
            >
              memory.dp.ua
            </a>
          </button>
        </div>
      </div>
      <div className="ebook__img_block box">
        <img className="img" src={memoryImg} alt="" />
      </div>
    </div>
  );
};

export default Memory;

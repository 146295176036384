import React from 'react'
import cn from 'classnames';

import ArrowBl from '../../../../../assets/images/targets/arrow_blue.png'

import styles from './styles.module.scss'

const Targets = () => {

  return (
    <div id="ex4" className={styles.targets}>
      <h3 className={styles.title__block}>Цілі</h3>
      <div className={styles.targets__content}>
        <div className={styles.target__arrow}>
          <img src={ArrowBl} alt="" />
        </div>

        {/* <div className="targets__arrow">
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
          <div className='anim_arrow'></div>
        </div> */}

        <div className={styles.target__texts}>
          <div className={cn(styles.target__text, styles.shadow_blue)}>
            <p id="target_text_p">
              <span className={cn(styles.target__num, styles.num__blue)}> Кожен із 103 тисяч учнів </span>
              дніпровських комунальних закладів загальної середньої освіти має учнівський
            </p>
          </div>

          <div className={cn(styles.target__text, styles.shadow_green)}>
            <p>
              <span className={cn(styles.target__num, styles.num__green)}>Кожен викладач та працівник </span>
              закладу  освіти має особисте посвідчення
            </p>
          </div>

          <div className={cn(styles.target__text, styles.shadow_orange)}>
            <p>
              <span className={cn(styles.target__num, styles.num__orange)}>100%-ве </span>
              користування цифровими підручниками
            </p>
          </div>

          <div className={cn(styles.target__text, styles.shadow_blue)}>
            <p id="target_text_p">
              Перехід усіх шкіл на
              <span className={cn(styles.target__num, styles.num__blue)}> онлайн-діловодство та звітність</span>
            </p>
          </div>

          <div className={cn(styles.target__text, styles.shadow_green)}>
            <p>
              <span className={cn(styles.target__num, styles.num__green)}>Організація зустрічей та заходів IT-індустрії</span>
              , для популяризації сфери діджитал-розвитку міста
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Targets
import React from 'react';
import { useNavigate } from 'react-router-dom';



import styles from './styles.module.scss';
import ErrorGhost from '../../ErrorGhost/';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
        <div className={styles.error}>
          <div className={styles.number}>
            4
          </div>

          <ErrorGhost/>

          <div className={styles.number}>4</div>
        </div>

        <div className={styles.text}>
          Ууупс. Сторінка, яку ви шукаєте, не існує.
        </div>

        <button
          className={styles.button}
          onClick={() => navigate('/')}
        >
          Повернутися на головну
        </button>
      </div>
  );
};

export default NotFoundPage;